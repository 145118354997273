import React from 'react';

import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

const StyledLink = styled.a``;
const AppleStoreBadge = styled(GooglePlayLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;

const GooglePlayLinkBtn = ({ ...props }) => (
  // <StyledLink href={StoresLinks.GooglePlay} target="_blank" {...props}>
  <AppleStoreBadge
    onClick={() => window.open(StoresLinks.GooglePlay, '_system')}
  />
  // </StyledLink>
);

export default GooglePlayLinkBtn;
