import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useMemo,
} from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import type { Color } from 'utils/theme';
import { CircularProgress } from '@material-ui/core';
import { tablet } from 'styles/breakpoints';

interface PrimaryButtonProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'button'>> {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  cornerVariant?: 'default' | 'round';
  minHeight?: number;
  color?: Color;
  colorActive?: Color;
  fontSize?: number;
  loading?: boolean;
}

const Container = styled(ButtonContainer)<{
  borderRadius: number;
  active?: boolean;
  disabled?: boolean;
  minHeight?: number;
  color: Color;
  colorActive: Color;
}>`
  display: flex;
  border: none;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 3.5)}rem;
  padding: ${({ minHeight }) => (minHeight && minHeight < 3 ? 0 : 0.81)}rem;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius}rem;
  background-color: ${({ theme, active, color, colorActive }) =>
    theme.colors[active ? colorActive : color]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: 0.15s;
  @media ${tablet} {
    min-height: ${({ minHeight }) => (minHeight ? minHeight : 3)}rem;
  }
`;

const ButtonText = styled(Text).attrs({
  type: 'buttonText',
  color: 'light0',
})<{ fontSize?: number }>`
  font-weight: 400;
  font-size: ${({ theme, fontSize }) =>
    fontSize || theme.typography.buttonText.fontSize}rem;
`;

const CircularProgressStyled = styled(CircularProgress)`
  height: 1.25rem;
  width: 1.25rem;
`;

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  children,
  active,
  disabled,
  cornerVariant = 'default',
  minHeight,
  color = 'primary',
  colorActive = 'greenDark',
  fontSize,
  loading,
  ...props
}) => {
  const borderRadius = useMemo(
    () => (cornerVariant === 'round' ? 10 : 0.5),
    [cornerVariant],
  );

  return (
    <Container
      onClick={onClick}
      as="button"
      borderRadius={borderRadius}
      active={active}
      disabled={disabled}
      minHeight={minHeight}
      color={color}
      colorActive={colorActive}
      {...props}
    >
      {loading ? (
        <CircularProgressStyled style={{ color: '#fff' }} size={20} />
      ) : (
        <ButtonText fontSize={fontSize}>{children}</ButtonText>
      )}
    </Container>
  );
};

export default React.memo(PrimaryButton);
