import React from 'react';

import AppleStoreLogo from 'assets/icons/appStore_badge.svg';
import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

const StyledLink = styled.a``;
const AppleStoreBadge = styled(AppleStoreLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;
const AppleStoreLinkBtn = ({ ...props }) => (
  // <StyledLink href={} target="_blank" {...props}>
  <AppleStoreBadge
    onClick={() => window.open(StoresLinks.AppleStore, '_system')}
  />
  // </StyledLink>
);

export default AppleStoreLinkBtn;
